<script setup>
import { computed } from "@vue/runtime-core"
import { Chart } from 'highcharts-vue';

const props = defineProps({
    data: Array
})

const graphData = computed(() => {
    let chartData = props.data
    if (chartData == ''){
        chartData = [{coordinator_firstname:'NO DATA'}]
    }
    let obj = {
        chart: {
            type: 'column'
        },
        title: {
            text: 'CRC Workload'
        },
        subtitle: {
            text: chartData[0].coordinator_firstname
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Points'
            }
        },
    }

    obj.xAxis = {
        categories: [],
        crosshair: true
    }
    obj.series = [
        {
            name: 'active_patients',
            data: []
        },
        {
            name: 'followup_patients',
            data: []
        },
        {
            name: 'secondary_crc',
            data: []
        },
        {
            name: 'pending_primary_crc',
            data: []
        },
        {
            name: 'pending_secondary_crc',
            data: []
        }
    ]

    //console.log(JSON.stringify(obj))
    //console.log("props.data: " + JSON.stringify(props.data))

    props.data.forEach(item => {
        //console.log("item.trial_name: " + item.trial_name)
        obj.xAxis.categories.push(item.trial_name)

        let val = 0

        val = (item.crcType === 1 && item.active_patients ? item.active_patients : 0)
        obj.series[0].data.push(val)

        val = (item.crcType === 1 && item.followup_patients ? item.followup_patients : 0)
        obj.series[1].data.push(val)
        // TODO: use the actual scoresheet values
        val = (item.crcType === 2 && item.trial_status_id != 3 ? 0.5 : 0)
        obj.series[2].data.push(val)

        val = (item.crcType === 1 && item.trial_status_id == 3 ? 0.7 : 0)
        obj.series[3].data.push(val)

        val = (item.crcType === 2 && item.trial_status_id == 3 ? 0.5 : 0)
        obj.series[4].data.push(val)
    })
    //console.log(JSON.stringify(obj))

    return obj;
})


</script>

<template>
    <Chart :options="graphData" v-if="data!=''"></Chart>   
    
</template>