import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import { library } from "@fortawesome/fontawesome-svg-core"
import { faUserNurse, faClinicMedical, faSyringe, faArrowRotateLeft, faSquarePlus, faSquareMinus, faSquarePen, faUpload} from "@fortawesome/free-solid-svg-icons"
import { faTimes,faPlus,faCircle, faFileArrowUp } from "@fortawesome/free-solid-svg-icons"
import { faPencil,faPuzzlePiece,faLightbulb, faChartLine } from "@fortawesome/free-solid-svg-icons"
import { faSync, faTrash, faMinus, faXmark, faCog, faFloppyDisk, faXmarkSquare } from "@fortawesome/free-solid-svg-icons"

library.add(faUserNurse, faClinicMedical,faSyringe)
library.add(faTimes,faPlus, faCircle, faArrowRotateLeft, faFileArrowUp, faUpload)
library.add(faPencil, faPuzzlePiece, faLightbulb, faChartLine)
library.add([faSync,faTrash,faMinus, faXmark, faCog,faFloppyDisk,faXmarkSquare, faSquarePlus, faSquareMinus, faSquarePen])

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import { createPinia } from 'pinia'
import UserWidgetVue from './components/UserWidget.vue'
import VueCookies from "vue3-cookies";

console.log('API_BASE_URL: ' + process.env.VUE_APP_API_BASE_URL)
//createApp(App).use(router).mount('#app')

createApp(App)
  .component("font-awesome-icon", FontAwesomeIcon)
  .component("user-widget", UserWidgetVue)
  .use(router)
  .use(createPinia())
  .use(VueCookies)
  // .provide('API_BASE_URL','http://3.19.225.130/api/v1/')
  // .provide('AUTH_BASE_URL','http://3.19.225.130/')
  // .provide('API_BASE_URL','http://localhost:8000/api/v1/')
  // .provide('AUTH_BASE_URL','http://localhost:8000/')
  .mount("#app");

  import { useCookies } from "vue3-cookies";
  const { cookies } = useCookies();
  

  // cookies.set('API_BASE_URL','http://3.19.225.130/api/v1/')
  // cookies.set('AUTH_BASE_URL','http://3.19.225.130/')
  // cookies.set('API_BASE_URL','http://localhost:8002/api/v1/')
  // cookies.set('AUTH_BASE_URL','http://localhost:8002/')
  // cookies.set('API_BASE_URL','https://api.staffnav.net/api/v1/')
  // cookies.set('AUTH_BASE_URL','https://api.staffnav.net/')

  cookies.set('API_BASE_URL',process.env.VUE_APP_API_BASE_URL)
  cookies.set('AUTH_BASE_URL',process.env.VUE_APP_AUTH_BASE_URL)


