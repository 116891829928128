<script setup>
import { ref, computed, watch } from 'vue'
import { useCookies } from "vue3-cookies";
import Datepicker from 'vue3-datepicker'
import { _constants, fetchCTList, deleteCT } from '../utils/datasource';
import CTList from './CTList.vue';
import cache from '../utils/cache';
import ErrorCard from '../components/ErrorCard.vue';


const { cookies } = useCookies();
const current_user = cookies.get('current_user')
const AUTH_BASE_URL = cookies.get('AUTH_BASE_URL')

// request options
const _headers = {
    'Content-Type': 'application/json',
    'Authorization': 'JWT ' + current_user.token
}
const API_BASE_URL = cookies.get('API_BASE_URL')

const nct_id = ref('')
const name = ref('')
const description = ref('')
const status = ref(3)
const phase = ref(1)
const start_date = ref((new Date()))
const end_date = ref((new Date()))
const isSubmitting = ref(false)
const message = ref(null)
const error = ref(null)
const items = ref([])
const selected_ct = ref()
const is_read_only = ref(true)
const is_editing = ref(false)
const is_adding = ref(false)
let last_added_ct = null
const data_error = ref(null)

const btnSubmitDisabled = computed(() => name.value == '' || description.value == '' || start_date.value == '' || end_date.value == '')

watch(items, (new_value) => {
    const found = new_value.find(x => x.id === last_added_ct)
    selected_ct.value = found ? found.id : null
    last_added_ct = null
    document.querySelector('#ctSelect').value = selected_ct.value
})

watch([start_date, end_date], () => {
    error.value = ''
})

function getTrial() {
    const ct = items.value.find(x => x.id == selected_ct.value)
    return ct.clinical_trial_sets.length > 0 ? ct.clinical_trial_sets[0].id : null
}

function getCTList(nocache) {
    fetchCTList(nocache).then((data) => {
        if (data.error) {
            console.log('ERROR:' + data.error)
            data_error.value = { code: data.error, text: "There was an error fetching data" }
        } else {
            items.value.length = 0
            items.value = data
            showCurrentSelection()
        }

    })
}

function addDefaultAttributes(ct_id, attribute_id) {
    const attr_data = {
        clinicalTrial: ct_id,
        scoreAttribute: attribute_id
    }
    const options = {
        method: 'POST',
        body: JSON.stringify(attr_data),
        headers: _headers
    }

    fetch(`${API_BASE_URL}` + "scoresheetset/", options)
        .then(response => response.json())
}

function validateDates() {
    return end_date.value > start_date.value
}

function submitProtocol() {

    if (!validateDates()) {
        error.value = "The End Date  should be after the Start Date."
        return
    }

    isSubmitting.value = true
    message.value = null
    error.value = null
    const prot_data = {
        nctID: nct_id.value,
        name: name.value,
        description: description.value,
        protocolStatus: status.value,
        protocolPhase: phase.value,
        modified_by: current_user.details.id
    }

    const ct_data = {
        start_date: start_date.value.toISOString(),
        end_date: end_date.value.toISOString(),
        status: status.value,
        protocol: null,
        modified_by: prot_data.modified_by

    }
    // console.log(prot_data)
    // console.log(ct_data)

    const verb = is_editing.value === true ? 'PATCH' : 'POST'
    console.log('verb' + verb)

    const options = {
        method: verb,
        body: JSON.stringify(prot_data),
        headers: _headers
    }

    let url = `${API_BASE_URL}` + "protocols/"

    if (verb === 'PATCH') {
        url += selected_ct.value + "/"
    }

    fetch(url, options)
        .then(response => response.json())
        .then(data => {
            const ret = JSON.stringify(data)
            // console.log(ret)
            isSubmitting.value = false

            if (ret.includes('exists')) {
                error.value = "Please enter a different name. " + data.name
                return
            }

            ct_data.protocol = data.id

            let trial_url = `${API_BASE_URL}` + "clinicaltrials/"
            if (verb === 'PATCH') {
                const trial_id = getTrial(data.id)
                trial_url += trial_id + "/"
            }

            options.body = JSON.stringify(ct_data)
            fetch(trial_url, options)
                .then(response => response.json())
                .then(data => {
                    // console.log(JSON.stringify(data))

                    isSubmitting.value = false
                    message.value = data.protocol_name
                    cache[_constants.CACHED_PROTOCOLS] = null

                    if (verb === 'PATCH') {
                        message.value += " was updated!"
                        cancel()
                        getCTList(true)

                    } else {
                        for (let i = 1; i <= 11; i++) {
                            addDefaultAttributes(data.id, i)
                        }
                        message.value += " was added!"
                        cancel()
                        getCTList(true)
                        last_added_ct = data.protocol
                    }

                    cache[_constants.CACHED_PROTOCOLS] = null
                    cache[_constants.CACHED_TRIALS] = null
                });

        });
}

function getDate(dt) {
    if (dt != null) {
        return new Date(dt)
    }

    return null
}

function ctChanged(ct_id) {
    console.log(ct_id)
    selected_ct.value = ct_id
    clearMessage()
    let ct = items.value.find(x => x.id == ct_id)
    console.log(JSON.stringify(ct))
    if (ct) {
        nct_id.value = ct.nctID
        name.value = ct.name
        description.value = ct.description
        status.value = ct.protocolStatus
        phase.value = ct.protocolPhase
        start_date.value = getDate(ct.clinical_trial_sets.length > 0 ? ct.clinical_trial_sets[0].start_date : null)
        end_date.value = getDate(ct.clinical_trial_sets.length > 0 ? ct.clinical_trial_sets[0].end_date : null)
    }
}

getCTList(false)

function showCurrentSelection() {
    document.querySelector('#ctSelect').value = selected_ct.value
}



function edit() {
    is_read_only.value = false
    is_editing.value = true
}

function cancel() {
    is_read_only.value = true
    is_editing.value = false
    is_adding.value = false
}

function add() {
    is_read_only.value = false
    is_adding.value = true
    selected_ct.value = null
    clearForm()
    showCurrentSelection()
}

function remove() {
    const go = confirm("Continue delete " + name.value + ". Are you sure?")
    if (go === true) {

        const trial_id = getTrial()

        deleteCT(selected_ct.value, trial_id).then(response => {
            console.log("deleteCT " + JSON.stringify(response))
            if (response.error) {
                error.value = "This record has references and cannot be deleted."
            }
            if (response.success) {
                message.value = response.success
                selected_ct.value = null
                cache[_constants.CACHED_PROTOCOLS] = null
                getCTList(true)
            }
        })
    }
}

function clearForm() {
    nct_id.value = ''
    name.value = ''
    description.value = ''
    status.value = 3
    phase.value = 1
    start_date.value = new Date()
    end_date.value = new Date()
    clearMessage()
}

function clearMessage() {
    message.value = null
    error.value = null
}
</script>

<template>
    <div class="container">
        <ErrorCard v-if="data_error" :error="data_error" />
        <div v-else>
            <h3>Clinical Trial <a :href="AUTH_BASE_URL + 'upload_csv/'" target="_blank">
                    <font-awesome-icon icon="file-arrow-up" class="clickable upload-blue" title="Upload csv file" />
                </a></h3>
            <div class="row">
                <div class="col-4">
                    <CTList v-if="!is_editing && !is_adding" :rows="14" :items="items" @selection-changed="ctChanged" />
                </div>
                <div class="col-4">
                    <div v-if="message" class="alert alert-success" role="alert">{{ message }}</div>
                    <div v-if="error" class="alert alert-danger" role="alert">{{ error }}</div>
                    <div class="row g-3 needs-validation" novalidate v-if="selected_ct || !is_read_only">
                        <div class="form-label mb-3">
                            <label for="nct_id">NCT ID</label>
                            <input type="text" v-model="nct_id" :disabled="is_read_only" class="form-control"
                                id="nct_id" placeholder="optional" />
                        </div>

                        <div class="form-label">
                            <label for="protocol_name">
                                Protocol Name
                                <span v-if="name == ''" class="form-required">*</span>
                            </label>
                            <input type="text" v-model="name" :disabled="is_read_only" class="form-control"
                                id="protocol_name" placeholder="required" required />
                        </div>

                        <div class="form-label">
                            <label for="protocol_desc">
                                Description
                                <span v-if="description == ''" class="form-required">*</span>
                            </label>
                            <textarea type="text" v-model="description" :disabled="is_read_only" class="form-control"
                                id="protocol_desc" placeholder="required" style="height:6em" />
                        </div>




                    </div>
                </div>

                <div class="col-4">
                    <div class="row g-3" v-if="selected_ct || !is_read_only">
                        <div class="form-label">
                            <label for="start_date">
                                Start Date
                                <span v-if="start_date == ''" class="form-required">*</span>
                            </label>
                            <Datepicker type="text" v-model="start_date" :disabled="is_read_only" class="form-control"
                                id="start_date" placeholder="Start Date" />
                        </div>

                        <div class="form-label">
                            <label for="end_date">
                                End Date
                                <span v-if="end_date == ''" class="form-required">*</span>
                            </label>
                            <Datepicker type="text" v-model="end_date" :disabled="is_read_only" class="form-control"
                                id="end_date" placeholder="End Date" />
                        </div>
                        <div class="form-label  mb-3">
                            <label>Protocol Status</label>
                            <select v-model="status" id="protocol_status" :disabled="is_read_only" class="form-select"
                                aria-label="Protocol Status">
                                <option value="1">Open CT</option>
                                <option value="2">CNPE</option>
                                <option value="3" selected>Pending</option>
                            </select>
                        </div>

                        <div class="form-label  mb-3">
                            <label>Protocol Phase</label>
                            <select v-model="phase" id="protocol_phase" :disabled="is_read_only" class="form-select"
                                aria-label="Protocol Phase">
                                <option value="1" selected>Phase I</option>
                                <option value="2">Phase II</option>
                                <option value="3">Phase III</option>
                                <option value="4">Phase IV</option>
                            </select>
                        </div>
                    </div>

                </div>
            </div>
            <div>
                <font-awesome-icon icon="floppy-disk" class="clickable fa-2x blue" @click="submitProtocol"
                    v-if="!is_read_only && !btnSubmitDisabled" />
                <font-awesome-icon icon="square-plus" class="clickable fa-2x green" @click="add" v-if="is_read_only" />
                <font-awesome-icon icon="square-pen" class="clickable fa-2x blue" @click="edit"
                    v-if="is_read_only && selected_ct" />
                <font-awesome-icon icon="xmark-square" class="clickable fa-2x gray" @click="cancel"
                    v-if="!is_read_only" />
                <font-awesome-icon icon="square-minus" class="clickable fa-2x red" @click="remove"
                    v-if="is_read_only && selected_ct" title="Delete" />
            </div>
        </div>
    </div>

</template>

<style>
.form-required {
    color: red;
}
</style>