<script setup>
import { ref, watch } from 'vue'
import EditableListPicker from './EditableListPicker.vue';

const props = defineProps({
  grid_columns: Array,
  grid_data: Array,
  title: String,
  model: String,
  is_editing: Boolean,
  show_edit_toggle: Boolean
})

//console.log("props:" + JSON.stringify(props))

const gridColumns = ref(props.grid_columns)
const data = ref(props.grid_data)
const is_adding = ref(false)

watch(() => props.grid_data, (newValue) => {
  data.value = newValue
});

watch(() => props.is_editing, (newValue) => {
  if (newValue == false) {
    syncData()
  }
});

const emit = defineEmits(['itemUpdateEvent', 'syncData', 'deleteData', 'saveItem', 'toggleEditing'])

function addItemUpdate(itemData) {

  console.log("addItemUpdate:" + JSON.stringify(itemData))

  if (props.model === "ct_crc") {
    emit("itemUpdateEvent", itemData)
  }

  if (!is_adding.value) {
    emit("itemUpdateEvent", itemData)
  }

  if (is_adding.value) {
    if (itemData.parent_field === "ct_crc_id") {
      if (itemData.field === "trial_name") {
        data.value[data.value.length - 1].clinicalTrial = itemData.id
      }

      if (itemData.field === "coordinator_type") {
        data.value[data.value.length - 1].crcType = itemData.id
      }
    }
  }

}

function addItem() {
  console.log(props.model)
  if (props.model === 'ct_crc') {
    data.value.push({ status: 'new', name: "...", crcType: null })
  }

  if (props.model === 'crc_ct') {
    data.value.push({ status: 'new', trial_name: "...", crcType: 2 })
  }

  is_adding.value = true
}

function syncData() {
  cancelAdd()
  emit("syncData", props.model)
}

function deleteData(itemData) {
  const go = confirm("Continue unassign. Are you sure?")
  console.log("go:" + go)
  if (go === true) {
    emit("deleteData", props.model, itemData)
  }
}

function cancelAdd() {
  is_adding.value = false
  const index = data.value.findIndex(item => item.status === "new")
  if (index != -1) {
    data.value.splice(index, 1)
  }

}

function saveItem() {
  const itemData = data.value[data.value.length - 1]
  emit("saveItem", props.model, itemData)
  is_adding.value = false
}

function toggleEditing() {
  emit("toggleEditing")
}

</script>

<template>
  <table>
    <thead>
      <tr>
        <th colspan="3" class="sg-header">
          <span @click="syncData" class="title">
            {{ title }} 
          </span>
          <span>
            
            <font-awesome-icon v-if="show_edit_toggle && !is_editing" icon="square-pen"
              class="clickable float-right fa-2x blue" title="Enter Edit Mode" @click="toggleEditing" />
            <font-awesome-icon v-if="show_edit_toggle && is_editing" icon="xmark-square"
              class="clickable float-right fa-2x gray" title="Exit Edit Mode" @click="toggleEditing" />
          </span>
        </th>
      </tr>
      <tr>
        <th v-for="col in gridColumns" v-bind:key="col">{{ col.label ? col.label : col.key }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="item in data" v-bind:key="item.id">
        <td v-for="col in gridColumns" v-bind:key="col.key">
          <span v-if="col.url && item.status != 'new'">
            <span v-if="is_editing" @click="deleteData" :id="item.id" class="clickable" alt="remove">
              <font-awesome-icon :id="item.id" icon="xmark" class="subtle-icon" />&nbsp;&nbsp;
            </span>
            <a :href="col.url + item[col.url_field]">{{ item[col.key] }}</a>
          </span>
          <span v-else>
            <span v-if="is_editing && col.editable || (item.status == 'new' && col.read_only !== true)">
              <EditableListPicker :field="col.key" :text="item[col.key]" :list="col.pickerList" parent_field="ct_crc_id"
                :default="item[col.keyField]" :parent_id="item.id" @item-update-event="addItemUpdate" />
            </span>
            <span v-else>{{ item[col.key] }}</span>
          </span>
        </td>
      </tr>
      <tr v-if="is_editing">
        <td v-if="!is_adding" class="add-item" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Add another"
          @click="addItem">
          <font-awesome-icon icon="square-plus" class="fa-2x green"/>
        </td>

        <td v-if="is_adding" class="add-item" data-bs-toggle="tooltip" data-bs-placement="bottom">
          <font-awesome-icon v-if="show_edit_toggle" icon="floppy-disk" @click="saveItem" class="clickable fa-2x"
            title="Save" />
          <font-awesome-icon icon="xmark-square" @click="cancelAdd" class="clickable red fa-2x" title="Cancel" />
          
        </td>
      </tr>
    </tbody>
  </table>

</template>

<style scoped>
/* table */
table {
  /* border: 2px solid #42b983; */
  border-radius: 3px;
  background-color: #fff;
  width: 100%;
}

th {
  background-color: #42b983;
  color: rgba(255, 255, 255, 0.66);
  cursor: pointer;
  user-select: none;
}

td {
  /* background-color: #f9f9f9; */
  text-align: left;
}

th,
td {
  min-width: 120px;
  padding: 3px 7px;
}

th.active {
  color: #fff;
}

th.active .arrow {
  opacity: 1;
}

.arrow {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  margin-left: 5px;
  opacity: 0.66;
}

.arrow.asc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #fff;
}

.arrow.dsc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #fff;
}

.add-item {
  cursor: pointer;
  user-select: none;
}

.subtle-icon {
  color: #42b983;
}

.clickable {
  cursor: pointer;
  user-select: none;
  padding-right: 5px;
  padding-left: 5px;
}

.float-right {
  float: right;
}

.red {
  color: red;
}

.title{
  font-size: 2em;
}
</style>