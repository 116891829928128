<script setup>
import { ref } from 'vue'
import SimpleGrid from './SimpleGrid.vue';
import { fetchComputedCRCScores, fetchComputedCTScores } from '../utils/datasource';
import ErrorCard from '../components/ErrorCard.vue';


const scores = ref([])
const coords = ref([])
const isLoading = ref(false)
const error = ref(null)

async function fetchScores() {
    isLoading.value = true
    scores.value.push({ score: 'calculating..' })
    fetchComputedCTScores().then(data => {
        isLoading.value = false
        if (data.error) {
            console.log('ERROR:' + data.error)
            error.value = { code:data.error, text:"There was an error fetching data"}
        } else {
            scores.value = data.filter(s => (s.status == 1 || s.status == 2))
        }

    })
}

async function fetchCRCScores() {
    isLoading.value = true
    coords.value.push({ score: 'calculating..' })
    fetchComputedCRCScores().then(data => {



        coords.value = data
        isLoading.value = false
    })
}


fetchScores()
fetchCRCScores()
</script>

<template>
    <div class="container">
        <ErrorCard v-if="error" :error="error" />
        <div v-else>
            <div class="row">
                <div class="col-6">
                    <SimpleGrid
                        :grid_columns="[{ key: 'id', label: 'ID' }, { key: 'trial_name', label: 'CT' }, { key: 'score', label: 'Score' }, { key: 'total', label: 'Total' }]"
                        :grid_data="scores" title="CT Scores" :is_loading="isLoading" />
                </div>

                <div class="col-6">
                    <SimpleGrid :grid_columns="[{ key: 'name', label: 'CRC' }, { key: 'score', label: 'Workload' },
                    { key: 'pct_load', label: '% Loading', suffix: '%' }]" :grid_data="coords" title="CRC Workload"
                        :is_loading="isLoading" />
                </div>
            </div>
        </div>
    </div>
</template>