<script setup>
import { ref } from 'vue'
import router from '../router'

import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

if (cookies.get('current_user')) {
    router.push('/')
}

const AUTH_BASE_URL = cookies.get('AUTH_BASE_URL')
const API_BASE_URL = cookies.get('API_BASE_URL')
const _headers = {
    'Content-Type': 'application/json',
}

defineProps({ isHeadless: Boolean })

let username = ref('')
let password = ref('')
let message = ref('')
const password_reset_url = ref(AUTH_BASE_URL + "accounts/password/reset/")

function signIn() {
    const url = `${AUTH_BASE_URL}` + "auth/token/?format=json"
    const options = {
        method: 'POST',
        body: JSON.stringify({
            username: username.value,
            password: password.value
        }),
        headers: _headers
    }
    fetch(url, options).then(response => response.json())
        .then(data => {
            //console.log("token"+data.token)
            if (data.token) {
                let user = { username: username.value, token: data.token, roles: [] }

                const detail_options = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'JWT ' + data.token
                    }
                }

                const user_detail_url = `${API_BASE_URL}` + "users/?format=json&username=" + username.value
                fetch(user_detail_url, detail_options).then(response => {
                    if (!response.ok) {
                        console.log(response)
                        message.value = "Your credentials did not work. Make sure you are in the correct site."
                    } else {
                        return response.json()
                    }
                }).then(userdata => {
                    user.details = userdata[0]
                    console.log(JSON.stringify(user))

                    user.details.groups.forEach(element => {
                        user.roles.push(element.name.toLowerCase())
                    });

                    cookies.set('current_user', user)
                    router.push({ path: '/', hash: '#' + data.token.slice(data.token.length - 5) })
                })
            } else {
                message.value = "Your credentials did not work. Please try again."
            }
        }).catch((error) => {
            console.log(error)
            if (error.message == "Failed to fetch") {
                router.push("/down")
            }
        })
}

function onChanged() {
    message.value = ''
}

</script>

<template>
    <div v-if="!isHeadless">

        <section class="vh-50">
            <div class="container py-5 h-100">
                <div class="row d-flex align-items-center justify-content-center h-100">

                    <div class="col-md-8 col-lg-7 col-xl-6">
                        <h1 class="centered" style="color:#337ec0">
                            <font-awesome-icon icon="puzzle-piece" style="color:#42b983" /> <span
                                style="color:#42b983">Staff</span>NAV
                        </h1>
                        <img src="~@/assets/login_img.png" class="img-fluid" alt="Phone image" />
                    </div>
                    <div class="col-md-7 col-lg-5 col-xl-5 offset-xl-1">
                        <h2>Sign In</h2>
                        <div>
                            <div class="login-error">{{ message }}</div>
                            <!-- Email input -->
                            <div class="form-floating mb-4">
                                <input v-model="username" type="text" id="emailInput"
                                    class="form-control form-control-lg" @keyup="onChanged" />
                                <label class="form-label" for="emailInput">Username</label>
                            </div>

                            <!-- Password input -->
                            <div class="form-floating mb-4">
                                <input v-model="password" type="password" id="passwordInput"
                                    class="form-control form-control-lg" @keyup="onChanged" @keyup.enter="signIn" />
                                <label class="form-label" for="passwordInput">Password</label>
                            </div>

                            <div class="d-flex justify-content-around align-items-center mb-4">
                                <!-- Checkbox -->
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value id="form1Example3" checked />
                                    <label class="form-check-label" for="form1Example3">Remember me</label>
                                </div>
                                <a :href="password_reset_url">Forgot password?</a>
                            </div>

                            <!-- Submit button -->
                            <div class="centered">
                                <button @click="signIn" @keyup.enter="signIn"
                                    class="btn btn-primary btn-lg btn-block">Sign in</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<style>
.divider:after,
.divider:before {
    content: "";
    flex: 1;
    height: 1px;
    background: #eee;
}

h1 {
    color: #41b983
}

h2 {
    color: #337ec0;
}

.centered {
    text-align: center;
    align-content: center;
}

.login-error {
    font-size: larger;
    color: red;
    font-weight: bold;
}
</style>