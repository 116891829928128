<script setup>
import { ref } from 'vue'

const props = defineProps({
    attribute_id: Number,
    scoresheet_id: Number,
    attribute_name: Number,
    trial_id: Number,
    text: String,
    list: Array,
    default: Number,
})

//console.log("props:" + JSON.stringify(props))

const emit = defineEmits(['itemUpdateEvent'])

const editing = ref(false)
const selected = ref(props.default)
let display_text = props.text == null ? ref('...') : ref(props.text)


function update(e) {
    editing.value = false
    console.log(e)
    if (selected.value) {
        var item = (props.list.find(i => i.id === selected.value))
        if(item==null) return
        display_text.value = item.description

        const data = {
            id: props.scoresheet_id,
            clinicalTrial: props.trial_id,
            scoreAttribute: props.attribute_id,
            scoreSheetItem: item.id,
            updated:false
        }

        console.log("data: "+ JSON.stringify(data))
        emit('itemUpdateEvent', data)
    }
}

function cancelEdit() {
    editing.value = false
}

</script>

<template>
    <div @click="editing = true">
        <div v-if="editing" id="parent">
            <select
                v-model="selected"
                @change="update"
                @blur="cancelEdit"
                @vnode-mounted="({ el }) => el.focus()">
                <option v-for="item in list" :key="item.id" :value="item.id">{{ item.description }}</option>
            </select>
            &nbsp;&nbsp;<font-awesome-icon icon="times" />
        </div>
        <div v-else class="editable">
            <span><font-awesome-icon icon="pencil" /> {{ display_text }}</span>
        </div>
    </div>
</template>

<style>
#parent {
    display: flex;
}

select {
    flex: 1;
}

.editable {
    cursor: pointer;
}
.editable span:hover {
    color: #42b983;
}

.svg-inline--fa{
    color:#405254;
    /* color: #C37779; */
}
</style>