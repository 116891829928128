import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'

import ClinicalTrial from '../views/ClinicalTrial.vue'
import About from '../views/About.vue'
import Coordinators from '../views/Coordinators.vue'
import Maintenance from '../views/Maintenance.vue'
import Scores from '../views/Scores.vue'
import { useCookies } from "vue3-cookies";
import PageNotFound from '../views/PageNotFound.vue'
import ServerDown from '../views/ServerDown.vue'
import Unauthorized from '../views/Unauthorized.vue'
import Forbidden from '../views/Forbidden.vue'
import Timeout from '../views/Timeout.vue'
import { ping } from '../utils/datasource';

const { cookies } = useCookies();
//cookies.set('current_user',{username:'nathan'})

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { authorize: [] }
  },
  {
    path: '/clinical_trial',
    name: 'Clinical Trial',
    component: ClinicalTrial,
    meta: { authorize: ['director', 'crc'] }
  },
  {
    path: '/coordinators',
    name: 'Coordinators',
    component: Coordinators,
    meta: { authorize: ['director', 'crc'] }
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: Maintenance,
    meta: { authorize: ['director', 'admin'] }
  },
  {
    path: '/scores',
    name: 'Scores',
    component: Scores,
    meta: { authorize: ['director'] }
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: { authorize: ['director', 'crc'] }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: Unauthorized
  },
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: Forbidden
  },
  {
    path: '/timeout',
    name: 'Timeout',
    component: Timeout
  },
  {
    path: '/down',
    name: 'ServerDown',
    component: ServerDown
  },
  {
    path: '/:catchAll(.*)*',
    name: "PageNotFound",
    component: PageNotFound,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router

function isAuthorized(authorize, currentUser) {
  let is_authorized = false
  authorize.forEach(auth => {
    currentUser.roles.forEach(role => {
      if (auth == role) {
        is_authorized = true
      }
    })
  })

  return is_authorized
}



router.beforeEach((to, from, next) => {

  if (to.name === 'ServerDown' || to.name === 'Login') {
    // do nothing
  } else {
    const { authorize } = to.meta;
    const currentUser = cookies.get('current_user');
    console.log(cookies.get('current_user'));

    if (authorize) {
      if (!currentUser) {
        if(to.name==='Home'){
          return next({ path: '/login', query: { return_url: to.path } });
        }else{
          return next({ path: '/unauthorized', query: { return_url: to.path } });
        }
        
      } else {

        ping().then(data => {
          if (data.error) {
            console.log('ERROR:' + data.error)
            if (data.error === 401) {
              cookies.remove('current_user')
              router.push("/unauthorized")
            }else if(data.error.message === 'Failed to fetch'){
              router.push("/down")
            }
          }
        }).catch((error) => {
            console.log(error)
            if (error.message == "Failed to fetch") {
                router.push("/down")
            }
        })

        if (authorize.length) {
          if (!isAuthorized(authorize, currentUser)) {
            return next({ path: '/forbidden' })
          }
        }

      }
    }
  }

next()
  
})

