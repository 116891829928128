<script setup>
import { ref, watch } from 'vue'
import FilteredList from '../components/FilteredList.vue';
import GraphOne from '../components/GraphOne.vue';
import GraphCTCount from '../components/GraphCTCount.vue';
import EditableGrid from '../components/EditableGrid.vue';
import { _constants } from '../utils/datasource';
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/stores/main'
import GraphCRCPatients from '../components/GraphCRCPatients.vue';
import { fetchCRCTrials, fetchTrials, getHeaders, addCRCAssignment, deleteCRCAssignment } from '../utils/datasource';
import { useCookies } from "vue3-cookies";
import cache from '../utils/cache';
import Loading from '../components/Loading.vue';
import ErrorCard from '../components/ErrorCard.vue';

const { cookies } = useCookies();

const route = useRoute()
const router = useRouter()
//console.log(route.query)

const store = useStore()

const API_BASE_URL = cookies.get('API_BASE_URL')
const items = ref([])
const grid_columns = ref([{ key: "trial_name", label: "Trial", url: "/#/clinical_trial?protocol=", url_field: 'protocol_id' },
{ key: "trial_status", label: "Trial Status", read_only: true },
{ key: "coordinator_type", label: "Role", keyField: 'crcType', editable: true, pickerList: [{ id: 1, description: "Primary CRC" }, { id: 2, description: "Secondary CRC" }] }])
let grid_data = ref([])
let crc_name = ref('')
const is_editing = ref(false)
const is_loading = ref(false)
const error = ref(null)

watch(items.value, () => {
    if (route.query.crc) {
        crcChanged(route.query.crc)
    }
    else if (store.currentCRC) {
        crcChanged(store.currentCRC)
    } else {
        crcChanged(items.value[0].id)
    }
})

getCrcTrials()
getTrials()

function getCrcTrials(skip_cache) {
    is_loading.value = true
    fetchCRCTrials(skip_cache).then(data => {
        is_loading.value = false
        items.value.length = 0

        if (data.error) {
            console.log('ERROR:' + data.error)
            error.value = { code:data.error, text:"There was an error fetching data"}
        } else {
            data.forEach((p) => {
                items.value.push({ id: p.id, name: p.firstname + " " + p.lastname, trials: p.trials, protocol_id: p.protocol_id });
            });
        }


    })
}

async function getTrials() {
    await fetchTrials().then(data => {
        // console.log("getTrials" + JSON.stringify(data))
        grid_columns.value[0].pickerList = []
        data.forEach(item => {
            const pl = { id: item.id, description: item.protocol_name }
            grid_columns.value[0].pickerList.push(pl)
        })

        // console.log("list" + JSON.stringify(grid_columns.value[0].pickerList))
    })
}

function crcChanged(crc_id) {
    console.log(crc_id)
    let crc = items.value.find(x => x.id == crc_id)
    if (crc) {
        const trials = crc.trials
        crc_name.value = crc.name
        console.log(trials)
        grid_data.value = trials
        // console.log("grid_data: " + JSON.stringify(grid_data.value))
        store.currentCRC = crc_id

        router.replace({ query: { crc: crc_id } })
    }
}

function updateCtCrcAssignment(itemData) {

    // put body data 
    const data = {
        "crcType": itemData.id,
    }

    console.log(data)

    const putOptions = {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: getHeaders()
    }

    const url = `${API_BASE_URL}` + "ct_crc/" + itemData.parent_id + "/"
    fetch(url, putOptions)
        .then(response => response.json())
        .then(data => {
            console.log("updateCT_CRC response:" + JSON.stringify(data))
            getCrcTrials(true)
            is_editing.value = false

            cache[_constants.CACHED_CRC_TRIALS] = null
        });
}

function addItemUpdate(itemData) {
    console.log("itemData:" + JSON.stringify(itemData))
    if (itemData.parent_field === "ct_crc_id") {
        if (itemData.field === "coordinator_type") {
            updateCtCrcAssignment(itemData)
        } else if (itemData.field === "trial_name") {
            console.log(itemData.field)
        }

    }
}

async function deleteData(model, itemData) {
    if (model === 'crc_ct') {
        console.log(itemData.target.parentElement.parentElement.getAttribute("ct_crc_id"))
        const ct_crc_id = itemData.target.parentElement.getAttribute("id")
        deleteCRCAssignment(ct_crc_id).then(() => {
            cache[_constants.CACHED_CRC_TRIALS] = null
            getCrcTrials(true)
        })
        is_editing.value = false
    }
}

function saveItem(model, itemData) {
    itemData.crc = store.currentCRC
    console.log("saveItem: " + JSON.stringify(itemData))
    addCRCAssignment(itemData, () => {
        is_editing.value = false
        getCrcTrials(true)
    })
}

function toggleEditing() {
    is_editing.value = !is_editing.value
}

</script>

<template>
    
    <div class="container g-100">
        <ErrorCard v-if="error" :error="error"/>
        <div v-else >
            <div class="row g-50 bottom-gap">
                <div class="col-lg-3 col-md-4 col-sm-6">
                    <FilteredList :rows="15" :items="items" @selection-changed="crcChanged" :filterDefaults="[-1]" />
                </div>

                <div class="col-lg-9 col-md-8 col-sm-6 m-10">
                    <Loading :is_loading="is_loading" />
                    <EditableGrid v-if="!is_loading" :grid_columns="grid_columns" :grid_data="grid_data"
                        :title="crc_name" model="crc_ct" @item-update-event="addItemUpdate" @delete-data="deleteData"
                        @save-item="saveItem" :is_editing="is_editing" :show_edit_toggle="true"
                        @toggle-editing="toggleEditing" />
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 g-10">
                    <GraphOne :data="grid_data" />
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-4 col-sm-4 g-10">
                    <GraphCTCount :data="grid_data" />
                </div>
                <div class="col-lg-6 col-md-8 col-sm-8 g-10">
                    <GraphCRCPatients :data="grid_data" />
                </div>
            </div>
        </div>
    </div>

</template>

<style>
.bottom-gap {
    margin-bottom: 2em;
}

select {
    width: 100% !important;
}
</style>
