<script setup>
import { ref, reactive, computed } from 'vue'
import FilteredList from '../components/FilteredList.vue';
import TrialEditor from '../components/TrialEditor.vue';
import TrialScoreSheet from '../components/TrialScoreSheet.vue';
import { useStore } from '@/stores/main'
import { useRoute } from 'vue-router'
import EditableGrid from '../components/EditableGrid.vue';
import { addCRCAssignment, fetchCoordinators, deleteCRCAssignment, fetchProtocols, updateProtocol } from '../utils/datasource';
import { useCookies } from "vue3-cookies";
import cache from '../utils/cache';
import { _constants } from '../utils/datasource';
import { Toast, Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import Loading from '../components/Loading.vue';
import ErrorCard from '../components/ErrorCard.vue';

const route = useRoute()
console.log(route.query)

const store = useStore()
const { cookies } = useCookies();
const current_user = cookies.get('current_user')
const error = ref(null)

// request options
const _headers = {
  'Content-Type': 'application/json',
  'Authorization': 'JWT ' + current_user.token
}

const _basicOptions = {
  headers: _headers
}

const page_id = 'CT1_'
const API_BASE_URL = cookies.get('API_BASE_URL')
let protocols = ref([])
let grid_data = ref([])
let scoresheet_items = []
let ctscores = ref({})
const active_patients = ref(null)
const followup_patients = ref(null)
const trialStatus = ref(null)
const protocol_id = ref(0)
let trial_id = ref(0)
let item_updates = reactive([])
let crc_updates = reactive([])
const crc_grid_columns = ref([{ key: "name", label: "Name", editable: true, url: "/#/coordinators?crc=", url_field: 'crc', pickerList: [] },
{ key: "coordinator_type", label: "Role", editable: true, keyField: 'crcType', pickerList: [{ id: 1, description: "Primary CRC" }, { id: 2, description: "Secondary CRC" }] }])
let crcData = ref([])
let trial_updated = reactive({ updated: false })
const filters = [{ id: 1, name: 'Open' }, { id: 2, name: 'CNPE' }, { id: 3, name: 'Pending' }]
const filterDefaults = ref([1, 2, 3])
const grid_cols = ref([{ key: 'id', label: 'ID' }, { key: 'nctID', label: 'NCT ID' }, { key: 'name', label: 'Name', clickable: true },
{ key: 'status_name', label: 'Status', suffix: '' }])

const is_editing = ref(false)
const is_loading = ref(false)

const btnSubmitDisabled = computed(() => {
  let has_updates = trial_updated.updated

  has_updates ||= item_updates.length > 0

  if (crc_updates.length > 0) {
    const ready = crc_updates.find(x => (x.isReady === true || x.ct_crc_id))
    has_updates ||= ready != null
  }

  return !has_updates
})

function getCached(key, defaultValue) {
  const cached = cache[key]
  if (cached != null) {
    return cached
  }

  return defaultValue
}

function getSettings() {
  filterDefaults.value = getCached(page_id + 'filterDefaults', [1, 2, 3])
}

async function fetchCoords() {
  await fetchCoordinators(crc_grid_columns.value[0].pickerList)
  crc_grid_columns.value[0].pickerList.forEach(item => { item.description = item.name })
}

async function fetchScores(id) {
  const url = `${API_BASE_URL}` + "ctscores/" + id + "/?format=json"
  const response = await fetch(url, _basicOptions)
  //console.log(response)
  const data = await response.json()
  console.log(data)
  ctscores.value = data;

  active_patients.value = data.active_patients
  followup_patients.value = data.followup_patients
  trialStatus.value = data.status

  console.log("active_patients.value " + active_patients.value)
  console.log("trialStatus " + trialStatus.value)
}

function refreshScores(prot_id) {
  protocol_id.value = prot_id

  //console.log("protocol_id.value: "+protocol_id.value)
  //console.log("protocols: " + JSON.stringify(protocols.value))

  const scoresheets = protocols.value.find(x => x.id == protocol_id.value).scoresheets;

  trial_id.value = 0
  if (scoresheets != null && scoresheets.length > 0) {
    console.log("sheet:" + JSON.stringify(scoresheets[0]))
    trial_id.value = scoresheets[0].id
  }

  ctscores.value.score_attribute_sets = []

  fetchScores(trial_id.value)
}

async function getProtocols(skip_cache) {
  is_loading.value = true
  await fetchProtocols(skip_cache).then((data) => {
    is_loading.value = false

    if (data.error) {
      console.log('ERROR:' + data.error)
      error.value = { code: data.error, text: "There was an error fetching data" }
    } else {
      protocols.value.length = 0
      data.forEach((p) => {
        const item = { id: p.id, name: p.name, scoresheets: p.clinical_trial_sets, nctID: p.nctID }
        if (p.clinical_trial_sets.length > 0) {
          item.status = p.clinical_trial_sets[0].status
          item.status_name = p.clinical_trial_sets[0].status_name
        }
        protocols.value.push(item);
      });
      console.log("store.currentProtocol: " + store.currentProtocol)
      if (route.query.protocol) {
        protocolChanged(route.query.protocol)
        showDetailModal()
      }
      else if (store.currentProtocol) {
        protocolChanged(store.currentProtocol)
      } else {
        protocolChanged(protocols.value[0].id)
      }
    }


  })

}

function fetchScoresheetItems() {
  const url = `${API_BASE_URL}` + "scoresheet_items/?format=json"
  fetch(url).then(response => response.json())
    .then(data => {
      scoresheet_items.length = 0
      data.forEach((p) => {
        scoresheet_items.push(p);
      });
      //console.log("scoresheet_items" + JSON.stringify(scoresheet_items));
    });
}

function fetchTrialCoordinators(trialID, isCallback = false) {

  if (isCallback) {
    if (trialID === -1) {
      showToastError()
      return
    } else {
      showToastSuccess()
    }
  }


  const url = `${API_BASE_URL}` + "trial_coordinators/" + trialID + "/?format=json"
  fetch(url).then(response => response.json())
    .then(data => {
      crcData.value = data.coordinators
      crcData.value.forEach(item => {
        item.name = item.coordinator_firstname + " " + item.coordinator_lastname
      })
      //console.log("crcData.value" + JSON.stringify(crcData.value))
    });
}



function resetChanges() {
  trial_updated.updated = false
  item_updates.length = 0
  crc_updates.length = 0
}

function protocolChanged(protocol_id) {
  console.log(protocol_id)
  const trials = protocols.value.find(x => x.id == protocol_id).trials
  console.log(trials)
  grid_data.value = trials
  console.log("grid_data: " + grid_data.value)
  refreshScores(protocol_id)
  store.currentProtocol = protocol_id
  fetchTrialCoordinators(trial_id.value)
  resetChanges()

}


function updateScore(data) {
  console.log("updating scoresheet")

  console.log("PUT " + JSON.stringify(data))

  const putOptions = {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: _headers
  }

  const url = `${API_BASE_URL}` + "scoresheetset/" + data.id + "/"
  fetch(url, putOptions)
    .then(response => response.json())
    .then(data => {
      item_updates = item_updates.filter(x => x.id != data.id)
      console.log("Updates left:" + item_updates.length)
      if (item_updates.length == 0) {
        refreshScores(protocol_id.value)
      }
      console.log(data)
    });
}

function updateTrial(trial_id) {

  if (trial_updated.updated === true) {
    // put body data 
    const data = {
      "protocol_id": protocol_id.value,
      "status": trialStatus.value,
      "active_patients": active_patients.value,
      "followup_patients": followup_patients.value,
      "previousStatus": null, // TODO: supply this later
      "modified_by": current_user.details.id
    }

    console.log(data)

    const putOptions = {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: _headers
    }

    const url = `${API_BASE_URL}` + "clinicaltrials/" + trial_id + "/"
    fetch(url, putOptions)
      .then(response => response.json())
      .then(data => {
        console.log("updateTrial response:" + JSON.stringify(data))

        const payload = { id: data.protocol, protocolStatus: data.status }
        updateProtocol(payload)
        getProtocols(true)
        cache[_constants.CACHED_CT_LIST] = null
        showToastSuccess()
      })
  }

}


async function updateCRCAssignments() {
  crc_updates.forEach(x => {
    if (x.isReady === true || x.ct_crc_id) {
      x.trial_id = trial_id.value
      addCRCAssignment(x, fetchTrialCoordinators)
    }
  })

  crc_updates.length = 0

}

async function update() {
  updateTrial(trial_id.value);
  item_updates.forEach(x => {
    updateScore(x)
  })
  item_updates = []
  await updateCRCAssignments()
  trial_updated.updated = false
  is_editing.value = false

  cache[_constants.CACHED_CT_SCORES] = null
  cache["rawctscores"] = null
  cache[_constants.CACHED_CRC_TRIALS] = null
}

function showToastSuccess() {
  const t = document.getElementById('toastSaved')
  const toast = new Toast(t)
  toast.show()
}

function showToastError() {
  const t = document.getElementById('toastError')
  const toast = new Toast(t)
  toast.show()
}

function updateItem(updatedItem) {
  let existingItem = item_updates.find(x => x.id === updatedItem.id)
  if (existingItem != null) {
    existingItem.scoreSheetItem = updatedItem.scoreSheetItem
  } else {
    item_updates.push(updatedItem)
  }

  console.log("item_updates: " + JSON.stringify(item_updates))
}

function updateTrialStatus(id) {
  trialStatus.value = id;
  trial_updated.updated = true
  console.log("trialStatus.value " + trialStatus.value)
}

function updateActivePatients(id) {
  active_patients.value = id;
  trial_updated.updated = true
  console.log("active_patients.value " + active_patients.value)
}

function updateFollowupPatients(id) {
  followup_patients.value = id;
  trial_updated.updated = true
  console.log("followup_patients.value " + followup_patients.value)
}

function addCRCUpdate(item) {
  let updatedItem = { clinicalTrial: trial_id.value }
  let existingItem = crc_updates.find(x => x.clinicalTrial === trial_id.value)
  if (existingItem != null) {
    updatedItem = existingItem
  } else {
    crc_updates.push(updatedItem)
  }
  // Make the updates
  if (item.field === 'name') {
    updatedItem.crc = item.id
  } else if (item.field === 'coordinator_type') {
    updatedItem.crcType = item.id
  }

  if (item.parent_id) {
    updatedItem.ct_crc_id = item.parent_id
  }

  if (updatedItem.clinicalTrial != null && updatedItem.crc != null && updatedItem.crcType != null) {
    updatedItem.isReady = true
  }

  console.log("crc_updates: " + JSON.stringify(crc_updates))
}

function syncData(model) {
  if (model === 'ct_crc') {
    fetchTrialCoordinators(trial_id.value)
  }
}

async function deleteData(model, itemData) {
  if (model === 'ct_crc') {
    //console.log(itemData.target.parentElement.parentElement.getAttribute("ct_crc_id"))
    const ct_crc_id = itemData.target.parentElement.getAttribute("id")
    await deleteCRCAssignment(ct_crc_id)
    fetchTrialCoordinators(trial_id.value)
    cache[_constants.CACHED_CRC_TRIALS] = null
    endEdit()
  }
}

function filterChanged(item) {
  console.log("filterDefaults:" + JSON.stringify(item))
  cache[page_id + 'filterDefaults'] = item
}

function showDetail(itemData) {
  console.log("itemData:" + JSON.stringify(itemData))
  protocolChanged(itemData.id)
  showDetailModal()
}

function startEdit() {
  is_editing.value = true
}

function endEdit() {
  is_editing.value = false
}

function showDetailModal() {
  var m = new Modal(document.getElementById("ctDetailModal"), { backdrop: true })
  m.show()
}


getProtocols()
fetchScoresheetItems()
fetchCoords()
getSettings()

</script>

<template>
  <!-- Modal -->
  <div class="modal fade" id="ctDetailModal" tabindex="-1" aria-labelledby="ctDetailModalLabel" aria-hidden="true">
    <div class="position-fixed top-0 start-50 translate-middle-x p-3" style="z-index: 99">
      <div id="toastSaved" class="toast align-items-center text-white bg-success border-0" role="alert"
        aria-live="assertive" aria-atomic="true">
        <div class="d-flex">
          <div class="toast-body">Save successful!</div>
          <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
            aria-label="Close"></button>
        </div>
      </div>

      <div id="toastError" class="toast align-items-center text-white bg-danger border-0" role="alert"
        aria-live="assertive" aria-atomic="true">
        <div class="d-flex">
          <div class="toast-body">Save failed! Check for duplicates</div>
          <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
            aria-label="Close"></button>
        </div>
      </div>
    </div>

    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="ctDetailModalLabel">Clinical Trial Details</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="row">
              <div class="col-lg-8 col-md-8 col-sm-12">
                <TrialEditor v-model:active_patients="active_patients" v-model:followup_patients="followup_patients"
                  v-model:trialStatus="trialStatus" :protocolName="ctscores.protocol_name"
                  :statusName="ctscores.status_name" :is_editing="is_editing" @update-trial-status="updateTrialStatus"
                  @update-active-patients="updateActivePatients" @update-followup-patients="updateFollowupPatients" />
                <TrialScoreSheet :trial="trial_id" :ctscores="ctscores" :scoresheet_items="scoresheet_items"
                  @item-updated="updateItem" :is_editing="is_editing" />
              </div>
              <div class="col-lg-4 col-md-8 col-sm-12">
                <EditableGrid title="CRC Assignments" :grid_columns="crc_grid_columns" :grid_data="crcData"
                  model="ct_crc" @item-update-event="addCRCUpdate" @sync-data="syncData" @delete-data="deleteData"
                  :is_editing="is_editing" :show_edit_toggle="false" />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="update" :disabled="btnSubmitDisabled"
            v-if="is_editing">Save Changes</button>
          <button type="button" class="btn btn-primary" @click="startEdit" v-if="!is_editing">Edit</button>
          <button type="button" class="btn btn-secondary" @click="() => {
            is_editing = false
            has_updates = false
          }" v-if="is_editing">Cancel</button>
          <button type="button" class="btn btn-secondary" @click="() => { is_editing = false }" data-bs-dismiss="modal"
            v-if="!is_editing">Close</button>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <ErrorCard v-if="error" :error="error" />
    <div v-else>
      <div class="row">
        <div class="col-12">

          <FilteredList :rows="1" :items="protocols" :grid_columns="grid_cols" @selection-changed="protocolChanged"
            v-bind:filters="filters" :filterDefaults="filterDefaults" @filter-changed="filterChanged"
            @show-detail="showDetail" />
          <Loading :is_loading="is_loading" />
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.modal-dialog {
  max-width: 90% !important;
  margin: 1.75rem auto;
}
</style>