<script setup>
import { ref,computed, watch } from 'vue'

const props = defineProps({
    parent_field: String,
    parent_id: Number,
    field: String,
    text: String,
    list: Array,
    default: Number,
})

//console.log("props:" + JSON.stringify(props))


const defaultText = computed(() =>{
  return props.text?props.text:'...'
})

watch(defaultText,(val, oldVal)=>{
    console.log("watch "+val + ' ' + oldVal)
})

const emit = defineEmits(['itemUpdateEvent'])

const editing = ref(false)  
const selected = ref(props.default)
const display_text = ref(null)

function blurred(){
    editing.value = false
    if(props.default != selected.value){
        update()
    }
}

function update() {
    editing.value = false
    if (selected.value) {
        var item = (props.list.find(i => i.id === selected.value))
        if(item==null) return

        display_text.value = item.description
    
        const data = {
            id:item.id,
            field:props.field,
            text: display_text.value,
            parent_field:props.parent_field,
            parent_id:props.parent_id,
        }

        console.log("update data: "+ JSON.stringify(data))
        emit('itemUpdateEvent', data)
    }
}
</script>

<template>
    <div @click="editing = true">
        <div v-if="editing" id="parent">
            <select
                v-model="selected"
                @change="update"
                @blur="blurred"
                @vnode-mounted="({ el }) => el.focus()"
            >
                <option disabled>Please choose</option>
                <option v-for="item in list" :key="item.id" :value="item.id">
                    {{ item.description }}
                </option>
            </select>
            &nbsp;<font-awesome-icon icon="times" />
        </div>
        <div v-else class="editable">
            <span><font-awesome-icon icon="pencil" class="clickable subtle-icon"/> {{ display_text?display_text:defaultText }} </span>
        </div>
    </div>
</template>

<style>
#parent {
    display: flex;
}

select {
    flex: 1;
}

.editable {
    cursor: pointer;
}
.editable span:hover {
    color: #42b983;
}

.svg-inline--fa{
    color:#405254;
    /* color: #C37779; */
}
</style>