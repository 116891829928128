<script setup>
import { ref, computed, watch, } from 'vue'
import { useCookies } from "vue3-cookies";
import CRCList from './CRCList.vue';
import { fetchCRCList, deleteCRC, _constants } from '../utils/datasource'
import cache from '../utils/cache';
import ErrorCard from '../components/ErrorCard.vue';

const { cookies } = useCookies();

const current_user = cookies.get('current_user')
const AUTH_BASE_URL = cookies.get('AUTH_BASE_URL')

// request options
const _headers = {
    'Content-Type': 'application/json',
    'Authorization': 'JWT ' + current_user.token
}
const API_BASE_URL = cookies.get('API_BASE_URL')

const firstname = ref('')
const lastname = ref('')
const competency = ref(1)
const isSubmitting = ref(false)
const message = ref(null)
const error = ref(null)
const items = ref([])
const selected_crc = ref()
const is_read_only = ref(true)
const is_editing = ref(false)
const is_adding = ref(false)
let last_added_crc = null
const data_error = ref(null)

const btnSubmitDisabled = computed(() => firstname.value == '' || lastname.value == '')

watch(items, (new_value) => {
    const found = new_value.find(x => x.id === last_added_crc)
    selected_crc.value = found ? found.id : null
    last_added_crc = null
    document.querySelector('#crcSelect').value = selected_crc.value
})

function getCRCList(nocache) {
    fetchCRCList(nocache).then((data) => {
        if (data.error) {
            console.log('ERROR:' + data.error)
            data_error.value = { code: data.error, text: "There was an error fetching data" }
        } else {
            items.value.length = 0
            items.value = data
        }
        
    })
}

function submitCRC() {
    isSubmitting.value = true
    message.value = null
    error.value = null
    const crc_data = {
        firstname: firstname.value,
        lastname: lastname.value,
        competency: competency.value,
        modified_by: current_user.details.id
    }

    console.log(crc_data)

    const verb = is_editing.value === true ? 'PATCH' : 'POST'
    console.log('verb' + verb)

    const options = {
        method: verb,
        body: JSON.stringify(crc_data),
        headers: _headers
    }

    let url = `${API_BASE_URL}` + "coordinators/"

    if (verb === 'PATCH') {
        url += selected_crc.value + "/"
    }

    fetch(url, options)
        .then(response => response.json())
        .then(data => {
            const ret = JSON.stringify(data)
            console.log(ret)
            isSubmitting.value = false

            if (ret.includes('unique')) {
                error.value = "A coordinator with that name already exists. " + data.non_field_errors
                return
            }

            message.value = data.firstname + " " + data.lastname

            if (verb === 'PATCH') {
                message.value += " was updated!"
                cancel()
                getCRCList(true)
            } else {
                message.value += " was added!"
                cancel()
                items.value.push(data)
                getCRCList(true)
                last_added_crc = data.id
            }

            cache[_constants.CACHED_CRC_TRIALS] = null
        });
}

function crcChanged(crc_id) {
    console.log(crc_id)
    selected_crc.value = crc_id
    clearMessage()
    let crc = items.value.find(x => x.id == crc_id)
    if (crc) {
        firstname.value = crc.firstname
        lastname.value = crc.lastname
        competency.value = crc.competency
    }
}

getCRCList(false)

function edit() {
    is_read_only.value = false
    is_editing.value = true
}

function cancel() {
    is_read_only.value = true
    is_editing.value = false
    is_adding.value = false
}

function add() {
    is_read_only.value = false
    is_adding.value = true
    selected_crc.value = null
    clearForm()
    document.querySelector('#crcSelect').value = selected_crc.value
}

function remove() {
    const go = confirm("Continue delete " + firstname.value + " " + lastname.value + ". Are you sure?")
    console.log("go:" + go)
    if (go === true) {
        deleteCRC(selected_crc.value).then(response => {
            console.log("deleteCRC " + JSON.stringify(response))
            if (response.error) {
                error.value = "This record has references and cannot be deleted."
            }
            if (response.success) {
                message.value = response.success
                selected_crc.value = null
                getCRCList(true)
                cache[_constants.CACHED_CRC_TRIALS] = null
            }
        })
    }
}

function clearForm() {
    firstname.value = ''
    lastname.value = ''
    competency.value = 1
    clearMessage()
}

function clearMessage() {
    message.value = null
    error.value = null
}


</script>



<template>
    <div class="container">
        <ErrorCard v-if="data_error" :error="data_error" />
        <div v-else>
            <h3>Coordinator <a :href="AUTH_BASE_URL + 'import_crc/'" target="_blank">
                    <font-awesome-icon icon="file-arrow-up" class="clickable upload-blue" title="Upload csv file" />
                </a></h3>

            <div class="row">
                <div class="col-5">
                    <CRCList v-if="!is_editing && !is_adding" :rows="14" :items="items"
                        @selection-changed="crcChanged" />
                </div>

                <div class="col-7">
                    <div v-if="message" class="alert alert-success" role="alert">{{ message }}</div>
                    <div v-if="error" class="alert alert-danger" role="alert">{{ error }}</div>
                    <div id="crcForm" v-if="selected_crc || !is_read_only">
                        <div class="row g-3 needs-validation" novalidate>
                            <div class="form-label mb-3">
                                <label for="firstname">
                                    Firstname
                                    <span v-if="firstname == ''" class="form-required">*</span>
                                </label>
                                <input type="text" v-model="firstname" class="form-control" id="firstname"
                                    placeholder="required" required :disabled="is_read_only" />
                            </div>

                            <div class="form-label">
                                <label for="lastname">
                                    Lastname
                                    <span v-if="lastname == ''" class="form-required">*</span>
                                </label>
                                <input type="text" v-model="lastname" class="form-control" id="lastname"
                                    placeholder="required" required :disabled="is_read_only" />
                            </div>
                            <div>
                                <label>Competency</label>
                                <select v-model="competency" id="competency" class="form-select" aria-label="Competency"
                                    :disabled="is_read_only">
                                    <option disabled>Select Competency:</option>
                                    <option value="1" selected>Novice</option>
                                    <option value="2">Intermediate</option>
                                    <option value="3">Advanced</option>
                                </select>
                            </div>

                            <div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <font-awesome-icon icon="floppy-disk" class="clickable fa-2x blue" @click="submitCRC"
                    v-if="!is_read_only && !btnSubmitDisabled" />
                <font-awesome-icon icon="square-plus" class="clickable fa-2x green" @click="add" v-if="is_read_only" />
                <font-awesome-icon icon="square-pen" class="clickable fa-2x blue" @click="edit"
                    v-if="is_read_only && selected_crc" />
                <font-awesome-icon icon="xmark-square" class="clickable fa-2x gray" @click="cancel"
                    v-if="!is_read_only" />
                <font-awesome-icon icon="square-minus" class="clickable fa-2x red" @click="remove"
                    v-if="is_read_only && selected_crc" title="Delete" />
            </div>
        </div>
    </div>
</template>

<style>
.form-required {
    color: red;
}

.clickable {
    cursor: pointer;
    user-select: none;
    padding-right: 5px;
    padding-left: 5px;
}

/* .red{
  color:#dc3444;
}

.green{
  color:#198753;
}

.blue{
    color:#0c6efd;
}

.gray{
    color:#6c757d
}

.white{
    color:#FFF
} */

/* .red{
  color:#9f7c74;
}

.green{
  color:#59830e;
}

.blue{
    color:#bc8c5e;
}

.gray{
    color:#dcbfb1
}

.white{
    color:#FFF
} */


.red {
    color: #d95d05;
}

.green {
    color: #82a16b;
}

.blue {
    color: #aad7ff;
}

.gray {
    color: #ded413
}

.white {
    color: #FFF
}

.black {
    color: #637121
}

.upload-blue {
    color: #367fc1;
}
</style>