import { useStore } from '../stores/main'

export default {
    setup() {
      const store = useStore()
  
      return {
        // you can return the whole store instance to use it in the template
        store,
      }
    },
  }