<script setup>
import { ref } from 'vue'
import GraphSingleValue from '../components/GraphSingleValue.vue';
import { fetchStats, fetchAverageCRCWorkload } from '../utils/datasource'
import ErrorCard from '../components/ErrorCard.vue';

const open_ct_data = ref([])
const crc_on_pending = ref([])
const crc_on_open = ref([])
const active_patients = ref([])
const followup_patients = ref([])
const average_crc_workload = ref([])
const error = ref(null)

getStats()

async function getStats() {
  await fetchStats(true).then((data) => {
    console.log("stats:" + JSON.stringify(data))

    if (data.error) {
      console.log('ERROR:' + data.error)
      error.value = { code: data.error, text: "There was an error fetching data" }
    } else {
      open_ct_data.value = [{ title: 'Open', value: data.open_trials + data.cnpe_trials }]
      crc_on_pending.value = [{ title: 'CRCs Assigned to Pending', value: data.crc_on_pending }]
      crc_on_open.value = [{ title: 'CRCs Assigned to Open CT', value: data.crc_on_open + data.crc_on_cnpe }]
      active_patients.value = [{ title: 'Active Patients', value: data.patients.active_patients ? data.patients.active_patients : 0 }]
      followup_patients.value = [{ title: 'Follow-up Patients', value: data.patients.followup_patients ? data.patients.followup_patients : 0 }]
    }


  })

  await fetchAverageCRCWorkload().then((data) => {
    average_crc_workload.value = [{ title: 'Average CRC Workload', value: data + '%' }]

  })
}

</script>

<template>
  <div class="container">
    <ErrorCard v-if="error" :error="error" />
    <div v-else>
      <div class="row">
        <GraphSingleValue header="Trial" :data="open_ct_data"></GraphSingleValue>
        <GraphSingleValue header="Trial" :data="active_patients"></GraphSingleValue>
        <GraphSingleValue header="Trial" :data="followup_patients"></GraphSingleValue>
      </div>
      <div class="row">
        <GraphSingleValue header="CRC" :data="crc_on_open" />
        <GraphSingleValue header="CRC" :data="crc_on_pending" />
        <GraphSingleValue header="CRC" :data="average_crc_workload"></GraphSingleValue>
      </div>
    </div>
  </div>
</template>
