<script setup>
import { computed } from "@vue/runtime-core"
import { Chart } from 'highcharts-vue';

const props = defineProps({
    data: Array
})

const graphData = computed(() => {
    if (props.data == '') return {};
    let obj = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        title: {
            text: 'CT Count'
        },
        subtitle: {
            text: props.data[0].coordinator_firstname
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: ''
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '{point.value}',
                    distance: -50
                },
                showInLegend: true
            }
        },
        series: [{
            name: 'CT Count',
            colorByPoint: true,
            data: [{
                name: 'Open CT',
                y: 0,
                sliced: true,
                selected: true
            }, {
                name: 'CNPE CT',
                y: 0
            }, {
                name: 'Secondary CRC',
                y: 0
            }, {
                name: 'Pending CT Primary CRC',
                y: 0
            }, {
                name: 'Pending CT Secondary CRC',
                y: 0
            }]
        }]
    }

    //console.log(JSON.stringify(obj))
    //console.log("props.data: " + JSON.stringify(props.data))

    props.data.forEach(item => {
        //console.log("item.trial_name: " + item.trial_name)

        if (item.crcType === 1 && item.trial_status_id == 1)
            obj.series[0].data[0].y++

        if (item.crcType === 1 && item.trial_status_id == 2)
            obj.series[0].data[1].y++

        if (item.crcType === 2 && item.trial_status_id != 3)
            obj.series[0].data[2].y++

        if (item.crcType === 1 && item.trial_status_id == 3)
            obj.series[0].data[3].y++

        if (item.crcType === 2 && item.trial_status_id == 3)
            obj.series[0].data[4].y++
    })
    //console.log(JSON.stringify(obj))

    return obj;
})


</script>

<template>
    <Chart :options="graphData" v-if="data!=''"></Chart>
</template>