<template>
  <div class="about">
    <div class="container">
      
      <h1>About</h1>
     StaffNAV helps you make better staffing decisions for effective clinical trial operations and balanced workloads.
    <div>
      <font-awesome-icon icon="lightbulb" class="fa-4x yellow"/>
    </div>
    
    </div>
  </div>
</template>

<style scoped>
.yellow{
  color:gold;
}
</style>
