
import cache from '../utils/cache';
import router from '../router'

import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export const _constants = {
    CACHED_PROTOCOLS: 'protocols',
    CACHED_STATS: 'stats',
    CACHED_TRIALS: 'clinicaltrials',
    CACHED_CRC_LIST: 'coordinators',
    CACHED_RAW_CT_SCORES: 'rawctscores',
    CACHED_CRC_TRIALS: 'coordinator_trials',
    CACHED_CT_SCORES: 'computed_ct_scores',
    CACHED_CRC_SCORES: 'computed_crc_scores',
}

export function getHeaders() {
    const token = cookies.get('current_user').token
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'JWT ' + token
    }

    return headers
}

export function getApiBaseUrl() {
    return cookies.get('API_BASE_URL')
}

export async function fetchProtocols(skip_cache = false) {

    if (skip_cache === false) {
        const cached = cache[_constants.CACHED_PROTOCOLS]
        if (cached != null) {
            console.log("Using CACHED_PROTOCOLS " + cached.length)
            return cached
        }
    }

    const url = getApiBaseUrl() + "protocols/?format=json"
    const options = { method: 'GET', headers: getHeaders() }
    return fetch(url, options).then(
        response => {
            if (!response.ok) {
                return { "error": response.status }
            }

            return response.json()
        }
    )
        .then(data => {
            //console.log("Using live:" + JSON.stringify(data))
            cache[_constants.CACHED_PROTOCOLS] = data
            return data
        }).catch((error) => {
            console.log(error)
            if (error.message == "Failed to fetch") {
                router.push("/down")
            }
        })
}


export async function fetchCRCTrials(skip_cache = false) {
    console.log("fetchCRCTrials")

    if (skip_cache === false) {
        const cached = cache[_constants.CACHED_CRC_TRIALS]
        if (cached != null) {
            console.log("Using CACHED_CRC_TRIALS " + cached.length)
            return cached
        }
    }

    const url = getApiBaseUrl() + "coordinator_trials/?format=json"
    const options = {
        method: 'GET',
        headers: getHeaders()
    }
    return fetch(url, options)
        .then(response => {
            if (!response.ok) {
                return { "error": response.status }
            }

            return response.json()
        })
        .then(data => {
            cache[_constants.CACHED_CRC_TRIALS] = data
            return data
        }).catch((error) => {
            console.log(error)
            if (error.message == "Failed to fetch") {
                router.push("/down")
            }
        })
}

export async function fetchCRCList(skip_cache = false) {

    if (skip_cache === false) {
        const cached = cache[_constants.CACHED_CRC_LIST]
        if (cached != null) {
            console.log("Using CACHED_CRC_LIST " + cached.length)
            return cached
        }
    }

    const url = getApiBaseUrl() + "coordinators/?format=json"
    const options = { method: 'GET', headers: getHeaders() }
    return fetch(url, options).then(
        response => {
            if (!response.ok) {
                return { "error": response.status }
            }

            return response.json()
        }
    )
        .then(data => {
            //console.log("Using live:" + JSON.stringify(data))
            cache[_constants.CACHED_CRC_LIST] = data
            return data
        }).catch((error) => {
            console.log(error)
            if (error.message == "Failed to fetch") {
                router.push("/down")
            }
        })
}

export async function fetchCTList(skip_cache = false) {

    if (skip_cache === false) {
        const cached = cache[_constants.CACHED_CT_LIST]
        if (cached != null) {
            console.log("Using CACHED_CT_LIST " + cached.length)
            return cached
        }
    }

    const url = getApiBaseUrl() + "protocols/?format=json"
    const options = { method: 'GET', headers: getHeaders() }
    return fetch(url, options).then(
        response => {
            if (!response.ok) {
                return { "error": response.status }
            }

            return response.json()
        }
    )
        .then(data => {
            //console.log("Using live:" + JSON.stringify(data))
            cache[_constants.CACHED_CT_LIST] = data
            return data
        }).catch((error) => {
            console.log(error)
            if (error.message == "Failed to fetch") {
                router.push("/down")
            }
        })
}

export async function fetchCoordinators(items) {
    console.log("fetchCoordinators")
    const url = getApiBaseUrl() + "coordinators/?format=json"
    const options = {
        method: 'GET',
        headers: getHeaders()
    }
    await fetch(url, options).then(
        response => {
            if (!response.ok) {
                return { "error": response.status }
            }

            return response.json()
        }
    )
        .then(data => {
            items.length = 0
            data.forEach((p) => {
                items.push({ id: p.id, name: p.firstname + " " + p.lastname });
            });
            //console.log("items" + JSON.stringify(items))
        }).catch((error) => {
            console.log(error)
            if (error.message == "Failed to fetch") {
                router.push("/down")
            }
        })
}

export async function updateProtocol(data) {
    const options = {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: getHeaders()
    }
    const url = getApiBaseUrl() + "protocols/" + data.id + '/?format=json'
    return fetch(url, options)
        .then(response => {
            if (!response.ok) {
                return { "error": response.status }
            }
            return response.json()
        }).then(data => {
            return data
        }).catch((error) => {
            console.log(error)
            if (error.message == "Failed to fetch") {
                router.push("/down")
            }
        })
}

export async function addCRCAssignment(data, cb) {
    console.log(data)

    let verb = 'POST'
    let url = getApiBaseUrl() + "ct_crc/"
    if (data.ct_crc_id) {
        verb = 'PATCH'
        url = getApiBaseUrl() + "ct_crc/" + data.ct_crc_id + '/'
    }

    const options = {
        method: verb,
        body: JSON.stringify(data),
        headers: getHeaders()
    }

    await fetch(url, options)
        .then(response => {
            if (!response.ok) {
                // cbError(response.status)
                return { "error": response.status }
            }

            return response.json()
        })
        .then(d => {
            console.log("addCRCAssignment response:" + JSON.stringify(d))
            if (d.error) {
                cb(-1, true)
            } else {
                cb(data.trial_id, true)
            }

            cache[_constants.CACHED_CRC_TRIALS] = null
        }).catch((error) => {
            console.log(error)
            if (error.message == "Failed to fetch") {
                router.push("/down")
            }
        })
}

export async function fetchStats(skip_cache = false) {

    if (skip_cache === false) {
        const cached = cache[_constants.CACHED_STATS]
        if (cached != null) {
            console.log("Using CACHED_STATS " + cached.length)
            return cached
        }
    }

    const url = getApiBaseUrl() + "stats/?format=json"
    const options = { method: 'GET', headers: getHeaders() }
    return fetch(url, options).then(
        response => {
            if (!response.ok) {
                return { "error": response.status }
            }

            return response.json()
        })
        .then(data => {
            //console.log("Using live:" + JSON.stringify(data))
            cache[_constants.CACHED_STATS] = data
            return data
        }).catch((error) => {
            console.log(error)
            if (error.message == "Failed to fetch") {
                router.push("/down")
            }
        })
}

export async function fetchAverageCRCWorkload() {
    const url = getApiBaseUrl() + "average_crc_score/?format=json"
    const options = { method: 'GET', headers: getHeaders() }
    return fetch(url, options).then(response => response.json())
        .then(data => {
            return data
        });
}


export async function fetchComputedCTScores() {

    // if (skip_cache === false) {
    //     const cached = cache[_constants.CACHED_CT_SCORES]
    //     if (cached != null) {
    //         console.log("Using CACHED_CT_SCORES " + cached.length)
    //         return cached
    //     }
    // }

    const url = getApiBaseUrl() + "computed_ct_scores/?format=json"
    const options = { method: 'GET', headers: getHeaders() }
    return fetch(url, options).then(
        response => {
            if (!response.ok) {
                return { "error": response.status }
            }

            return response.json()
        }
    )
        .then(data => {
            cache[_constants.CACHED_CT_SCORES] = data
            return data
        }).catch((error) => {
            console.log(error)
            if (error.message == "Failed to fetch") {
                router.push("/down")
            }
        })
}


export async function fetchComputedCRCScores() {

    // if (skip_cache === false) {
    //     const cached = cache[_constants.CACHED_CRC_SCORES]
    //     if (cached != null) {
    //         console.log("Using CACHED_CRC_SCORES " + cached.length)
    //         return cached
    //     }
    // }

    const url = getApiBaseUrl() + "computed_crc_scores/?format=json"
    const options = { method: 'GET', headers: getHeaders() }
    return fetch(url, options).then(response => response.json())
        .then(data => {
            cache[_constants.CACHED_CRC_SCORES] = data
            return data
        }).catch((error) => {
            console.log(error)
            if (error.message == "Failed to fetch") {
                router.push("/down")
            }
        })
}


export async function fetchTrials(skip_cache = false) {

    if (skip_cache === false) {
        const cached = cache[_constants.CACHED_TRIALS]
        if (cached != null) {
            console.log("Using CACHED_TRIALS " + cached.length)
            return cached
        }
    }

    const url = getApiBaseUrl() + "clinicaltrials/?format=json"
    const options = { method: 'GET', headers: getHeaders() }
    return fetch(url, options).then(response => response.json())
        .then(data => {
            //console.log("Using live:" + JSON.stringify(data))
            cache[_constants.CACHED_TRIALS] = data
            return data
        }).catch((error) => {
            console.log(error)
            if (error.message == "Failed to fetch") {
                router.push("/down")
            }
        })
}


// DELETE
export async function deleteCRCAssignment(ct_crc_id) {
    const options = {
        method: 'DELETE',
        headers: getHeaders()
    }

    return fetch(getApiBaseUrl() + "ct_crc/" + ct_crc_id + '/?format=json', options).then((response) => {
        if (!response.ok) {
            // cbError(response.status)
            return { "error": response.status }
        }
        cache[_constants.CACHED_CRC_TRIALS] = null
    }).catch((error) => {
        console.log(error)
        if (error.message == "Failed to fetch") {
            router.push("/down")
        }
    })
}

export async function deleteCRC(crc_id) {
    const options = {
        method: 'DELETE',
        headers: getHeaders()
    }

    return fetch(getApiBaseUrl() + "coordinators/" + crc_id + '/?format=json', options).then(response => {
        if (!response.ok) {
            // cbError(response.status)
            return { "error": response.status }
        }
        cache[_constants.CACHED_CRC_TRIALS] = null
        return { "success": "Record was deleted." }
    }).catch((error) => {
        console.log(error)
        if (error.message == "Failed to fetch") {
            router.push("/down")
        }
    })
}

export async function deleteCT(protocol_id, trial_id) {
    const options = {
        method: 'DELETE',
        headers: getHeaders()
    }

    if (trial_id) {
        return fetch(getApiBaseUrl() + "clinicaltrials/" + trial_id + '/?format=json', options).then(response => {
            console.log('clinicaltrials' + response)
            if (!response.ok) {
                return { "error": response.status }
            }
            return { "success": "Record was deleted." }
        }).then(result => {
            if (result.success) {
                return fetch(getApiBaseUrl() + "protocols/" + protocol_id + '/?format=json', options).then(response => {
                    console.log('protocols' + response)
                    if (!response.ok) {
                        return { "error": response.status }
                    }
                    return { "success": "Record was deleted." }
                })
            } else {
                return { "error": "Not able to delete" }
            }
        }).catch((error) => {
            console.log(error)
            if (error.message == "Failed to fetch") {
                router.push("/down")
            }
        })
    } else {
        return fetch(getApiBaseUrl() + "protocols/" + protocol_id + '/?format=json', options).then(response => {
            console.log('protocols2' + response)
            if (!response.ok) {
                return { "error": response.status }
            }
            return { "success": "Record was deleted." }
        })
    }
}

export function ping() {
    console.log('datasource ping')
    const url = getApiBaseUrl() + "ping/?format=json"
    const options = { method: 'GET', headers: getHeaders() }
    return fetch(url, options).then(
        response => {
            if (!response.ok) {
                return { "error": response.status }
            }
            // return { "error": 401 }
            return response.json()
        }
    ).catch((error) => {
        console.log(error)
        return { "error": error }
    })
}