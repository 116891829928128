<script setup>
import { ref } from 'vue'

const props = defineProps({
    grid_columns: Array,
    grid_data:Array,
    title:String,
    is_loading:Boolean,
})

// console.log("props:" + JSON.stringify(props))

const gridColumns = ref(props.grid_columns)

const emit = defineEmits(['showDetail'])

function showDetail(itemData) {
  console.log("showDetail:" + JSON.stringify(itemData))
  emit("showDetail", itemData)
}

</script>

<template>
    <table>
      <thead>
          <tr>
                <th colspan="3" class="sg-header">
                    <span>{{ title }} </span>
                </th>
            </tr>
        <tr>
          <th v-for="col in gridColumns" v-bind:key="col">{{ col.label?col.label:col.key }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in grid_data" v-bind:key="item.id">
          <td v-for="col in gridColumns" v-bind:key="col.key">
              <span v-if="col.url">
                  <a :href="col.url + item[col.url_field]" >{{ item[col.key] }}</a>
              </span>

              <span v-if="col.clickable">
                  <a class="clickable" @click="showDetail(item)">{{ item[col.key] }}</a>
              </span>

              <span v-else>
                {{ item[col.key] }} {{ col.suffix }} 
                <font-awesome-icon icon="cog" class="fa-spin" v-if="is_loading===true && col.key=='score'"/>
              </span>
              
          </td>
        </tr>
      </tbody>
    </table>
</template>

<style>
/* table */
table {
  /* border: 2px solid #42b983; */
  border-radius: 3px;
  background-color: #fff;
  width:100%;
}

th {
  background-color: #42b983;
  color: rgba(255, 255, 255, 0.66);
  cursor: pointer;
  user-select: none;
}

td {
  /* background-color: #f9f9f9; */
}

th,
td {
  min-width: 60px;
  padding: 3px 3px;
}

th.active {
  color: #fff;
}

th.active .arrow {
  opacity: 1;
}

.arrow {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  margin-left: 5px;
  opacity: 0.66;
}

.arrow.asc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #fff;
}

.arrow.dsc {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #fff;
}

.clickable{
  cursor: pointer;  
}
</style>
