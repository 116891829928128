<script setup>
import { ref, watch, computed } from 'vue'


const props = defineProps({
  page_id: String,
  items: Array,
  rows: Number,
})

// console.log("props:" + JSON.stringify(props))
const selected = ref('')
const prefix = ref('')
const items = ref(props.items)


const filteredItems = computed(() =>
  props.items.filter((n) => 
   n.firstname.toLowerCase().includes(prefix.value.toLowerCase()) ||
   n.lastname.toLowerCase().includes(prefix.value.toLowerCase())
  )
)

watch(props.items, () => {
  items.value = props.items
})

const emit = defineEmits(['selectionChanged', 'filterChanged', 'showDetail'])

watch(selected, () => {
  console.log(selected.value)
  emit('selectionChanged', selected.value)
})

function clearFilter(){
  prefix.value = ''
}

</script>

<template>
  <div>
    <span class="input-group crc-filter">
      <input
        v-model="prefix"
        class="form-control"
        placeholder="Filter"
        aria-label="filter"
        aria-describedby="button-clear"
      />
      <button class="btn btn-outline-secondary" type="button" id="button-clear" @click="clearFilter">Clear</button>
    </span>
    <div>
      <select :size="rows" v-model="selected" class="crc-filter" id="crcSelect">
        <option v-for="item in filteredItems" :key="item.id" :value="item.id">{{ item.firstname }} {{ item.lastname }}</option>
      </select>
    </div>

  </div>
</template>

<style>
* {
  font-size: inherit;
}

input {
  display: block;
  width: 90%;  
}

.crc-filter{
  width: 90% !important;
  margin-bottom: 7px;
}

select {
  float: left;
  width: 14em;
  outline:none;
  border:white;
}

select:focus{
    /* border-color: gray; */
    outline:none;
    border:white;
}

.buttons {
  clear: both;
}

button + button {
  margin-left: 5px;
}

.list-filter {
  margin-bottom: 5px;
  border:white;
  
}





</style>