<script setup>
import { ref, computed, watch } from 'vue'
import SimpleGrid from './SimpleGrid.vue';


const props = defineProps({
  page_id: String,
  items: Array,
  rows: Number,
  filters: Array,
  filterDefaults: Array,
  grid_columns: Array,
})

// console.log("props:" + JSON.stringify(props))
const selected = ref('')
const prefix = ref('')
const items = ref([])
const grid_columns = ref(props.grid_columns)
const filters = ref(props.filters)
const checkedFilters = ref(props.filterDefaults)

watch(() => checkedFilters.value, (newVal) => {
  emit('filterChanged', newVal)
})

const filteredItems = computed(() =>
  items.value.filter((n) => {
    return n.name.toLowerCase().includes(prefix.value.toLowerCase())
      || n.id === parseInt(prefix.value)
      || (n.nctID?n.nctID.toLowerCase().includes(prefix.value.toLowerCase()):false)
      && (checkedFilters.value.includes(n.status) || checkedFilters.value.includes(-1))
  })
)

const emit = defineEmits(['selectionChanged', 'filterChanged', 'showDetail'])

watch(props.items, () => {
  //console.log(props.items)
  items.value = props.items
})

watch(selected, () => {
  console.log(selected.value)
  emit('selectionChanged', selected.value)
})

function clearFilter(){
  prefix.value = ''
}

function showDetail(itemData) {
  console.log("showDetail:" + JSON.stringify(itemData))
  emit("showDetail", itemData)
}

</script>

<template>
  <div>
    <span v-for="f in filters" v-bind:key="f.id" class="form-check form-check-inline">
      <input
        class="form-check-input"
        type="checkbox"
        :value="f.id"
        :id="f.name"
        checked
        v-model="checkedFilters"
      />
      <label class="form-check-label" for="{{f.name}}">{{ f.name }}</label>
    </span>
    <span class="input-group">
      <input
        v-model="prefix"
        class="form-control"
        placeholder="Filter"
        aria-label="filter protocols"
        aria-describedby="button-clear"
      />
      <button class="btn btn-outline-secondary" type="button" id="button-clear" @click="clearFilter">Clear</button>
    </span>
    <div v-if="grid_columns != null">
      <SimpleGrid :grid_data="filteredItems" :grid_columns="grid_columns"
        @show-detail="showDetail"
      />
    </div>
    <div v-else>
      <select :size="rows" v-model="selected" >
        <option v-for="item in filteredItems" :key="item.id" :value="item.id">{{ item.name }}</option>
      </select>
    </div>
    

  </div>
</template>

<style>
* {
  font-size: inherit;
}

input {
  display: block;
  width: 14em;  
}

select {
  float: left;
  width: 14em;
  border-color: gray;
  outline:none;
}

.buttons {
  clear: both;
}

button + button {
  margin-left: 5px;
}

.list-filter {
  margin-bottom: 5px;
  border:white;
  
}

select:focus{
    border-color: gray;
    outline:none;
    border:white;
}

</style>