<script setup>
import { ref, onMounted, onUnmounted, watch } from 'vue'
import { useCookies } from "vue3-cookies";
import { useRoute } from 'vue-router'
import router from '../router'

const { cookies } = useCookies();
const route = useRoute()
function getHeaders() {
    const user = cookies.get('current_user');
    const token = user ? user.token : ''
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'JWT ' + token
    }

    return headers
}

defineExpose(
    {
        getHeaders
    }
)

const AUTH_BASE_URL = cookies.get('AUTH_BASE_URL')
const API_BASE_URL = cookies.get('API_BASE_URL')

const TOKEN_LIFESPAN = 45 * 60 // 45 minutes
const MAX_INACTIVITY = 30 * 60 // 20 minutes
const current_user = ref(cookies.get('current_user'))
const inactive_seconds = ref(MAX_INACTIVITY)
const token_seconds = ref(TOKEN_LIFESPAN)
let timer
const route_name = ref('')
let blurred = false

watch(() => route.hash, (toParams) => {
    console.log(toParams)
    current_user.value = cookies.get('current_user')
})

watch(() => route.name, (name) => {
    route_name.value = name
    console.log(name)
    current_user.value = cookies.get('current_user')

    for (let element of document.getElementsByClassName('modal-backdrop')) {
        element.style.display = "none";
    }
    document.body.style.overflow = "visible";
})

onMounted(() => {
    trackUserActivity()
    console.log("hello mounted")
    timer = setInterval(() => {

        if (current_user.value != null) {
            inactive_seconds.value-=3
            token_seconds.value-=3

            if (inactive_seconds.value <= 0) {
                signOut('timeout')
            }

            if (token_seconds.value <= 0) {
                getRefreshToken(current_user.value.token)
            }
        }

    }, (3000));
})

onUnmounted(() => {
    console.log("hello unmounted")
    clearInterval(timer);
})



function signOut(type) {

    if(blurred) return

    current_user.value = null
    cookies.remove('current_user')
    token_seconds.value = TOKEN_LIFESPAN
    inactive_seconds.value = MAX_INACTIVITY

    if (type==='token') {
        router.push('/unauthorized')
    } else if(type==='timeout') {
        router.push('/timeout')
    }else{
        router.push('/login')
    }

}

function getRefreshToken(token) {

    if(blurred) return

    const url = `${AUTH_BASE_URL}` + "auth/refresh_token/?format=json"
    const options = {
        method: 'POST',
        body: JSON.stringify({
            token: token
        }),
        headers: getHeaders()
    }
    fetch(url, options).then(response => response.json())
        .then(json => {
            if (json.token) {
                console.log("token refreshed: " + json.token)
                const user = current_user.value
                user.token = json.token
                cookies.set('current_user', user)
                current_user.value = user
                token_seconds.value = TOKEN_LIFESPAN
            } else {
                console.log("WARNING: Not able to get refresh token")
                signOut('token')
            }
        });
}

function ping() {
    console.log('ping')
    const url = `${API_BASE_URL}` + "ping/?format=json"
    const options = {
        method: 'GET',
        headers: getHeaders()
    }
    fetch(url, options).then(response => {
        if (!response.ok) {
            console.error(response.status)
            if(response.status===401){
                signOut('token')
            }else{
                router.push('/error')
            }
        } else {
            console.log('pong')
            if (route_name.value === 'ServerDown') {
                router.push('/')
            }
        }
    }).catch((error) => {
        console.log(error)
        if (error.message == "Failed to fetch") {
            router.push("/down")
        }
    })
}

function userIsActive() {
    inactive_seconds.value = MAX_INACTIVITY
}

function windowActivated() {
    blurred = false
    if (route_name.value == 'Login') {
        router.push('/')
        return
    }

    console.log("windowActivated")
    current_user.value = cookies.get('current_user')
    inactive_seconds.value = cookies.get('inactive_seconds')
    token_seconds.value = cookies.get('token_seconds')
    if (current_user.value == null) {
        signOut('token')
    } else {
        ping()
    }

}

function windowDeactivated() {
    blurred = true
    cookies.set('inactive_seconds', inactive_seconds.value)
    cookies.set('token_seconds', token_seconds.value)
}

function trackUserActivity() {
    window.onfocus = windowActivated;
    window.onblur = windowDeactivated;
    window.onload = userIsActive;
    window.onclick = userIsActive;
    window.onkeypress = userIsActive;
    window.ontouchstart = userIsActive;
    window.onmousemove = userIsActive;
    window.onmousedown = userIsActive;
    window.addEventListener("mousemove", userIsActive);
    window.addEventListener("scroll", userIsActive);
    window.addEventListener("keydown", userIsActive);
    window.addEventListener("resize", userIsActive);
}

function hasPermission(authorize) {
    let has_permission = false
    authorize.forEach(auth => {
        current_user.value.roles.forEach(role => {
            if (auth == role) {
                has_permission = true
            }
        })
    })

    return has_permission ? 'active' : 'disabled'
}

</script>   

<template>
    <div v-if="current_user && route_name != 'ServerDown'">
        <nav class="navbar navbar-light">
            <div id="nav">
                <span class="logo-text">
                    <font-awesome-icon icon="puzzle-piece" style="color:#42b983" />
                    Staff<span style="color:#337ec0">NAV</span>
                </span>
                <router-link class="nav-link active" to="/">Home</router-link>
                <router-link class="nav-link active" to="/clinical_trial">Clinical Trial</router-link>
                <router-link class="nav-link active" to="/coordinators">Coordinators</router-link>
                <router-link :class="'nav-link ' + hasPermission(['director'])" to="/scores">Scores</router-link>
                <router-link :class="'nav-link ' + hasPermission(['director'])" to="/maintenance">Maintenance
                </router-link>
                <router-link class="nav-link active" to="/about">About</router-link>

            </div>
            <span class="current-user">
                Hello, {{ current_user.username }}
                <a href="#" @click="signOut">Logout</a>
            </span>
        </nav>
    </div>
    <!-- <div v-if="current_user===null">
        <div v-if="route_name!='Login'" class="alert alert-danger" role="alert">
            You were signed out
            <router-link to="/">Sign In Again</router-link>
        </div>
    </div> -->
    
    <!-- <span style="color:red">{{ token_seconds }} {{ inactive_seconds }}</span> -->
</template>

<style>
.current-user {
    margin-left: 3em;
    margin-right: 1em;
    color: black;
}

.nav-link {
    display: inline-block;
}

.nav-link.disabled {
    color: silver !important;
}
</style>