<script setup>
import EditableListPicker from "./EditableListPicker.vue";

defineProps({
    active_patients: Number,    
    followup_patients: Number,
    trialStatus: Number,
    protocolName:String,
    statusName:String,
    is_editing: String,
})


const emit = defineEmits([
    'updateActivePatients',
    'updateFollowupPatients',
    'updateTrialStatus',
])

const statuses = [ // TODO: read from DB
    { id: 1, description: 'Open' },
    { id: 2, description: 'CNPE' },
    { id: 3, description: 'Pending' },
]

function statusChanged(data){
    console.log("received: " + JSON.stringify(data))
    emit('updateTrialStatus', data.id)
}

</script>

<template>
    <table>
        <thead>
            <tr>
                <th colspan="3" class="sg-header">
                    <span class="title">{{ protocolName }}</span>
                </th>
            </tr>
            <tr>
                <th>Status</th>
                <th>Active Patients</th>
                <th>Followup Patients</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td v-if="is_editing">
                    <EditableListPicker 
                    :list="statuses" 
                    :text="statusName" 
                    :default="trialStatus"
                    @item-update-event="statusChanged"/>
                    
                </td>
                <td v-else>
                    {{ statusName }}
                </td>
                <td>
                    <span v-if="is_editing">
                        <input
                            type="number"
                            class="num-patients"
                            :value="active_patients"
                            @input="$emit('updateActivePatients', $event.target.value)"
                        />
                    </span>
                    <span v-else>
                        {{ active_patients }}
                    </span>
                </td>
                <td>
                    <span v-if="is_editing">
                        <input
                            type="number"
                            class="num-patients"
                            :value="followup_patients"
                            @input="$emit('updateFollowupPatients', $event.target.value)"
                        />
                    </span>
                    <span v-else>
                        {{ followup_patients }}
                    </span>
                    
                </td>
            </tr>
        </tbody>
    </table>
</template>

<style scoped>
    .num-patients{
        width: 80px;
    }

    .title{
        font-size: 2em;
    }
</style>