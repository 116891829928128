<script setup>
import { computed } from "@vue/runtime-core"
import { Chart } from 'highcharts-vue';

const props = defineProps({
    data: Array
})

const graphData = computed(() => {
    if (props.data == '') return {};
    let obj = {
        chart: {
            type: 'column'
        },
        title: {
            text: 'Patient Count'
        },
        subtitle: {
            text: props.data[0].coordinator_firstname
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Patients'
            }
        },
    }

    obj.xAxis = {
        categories: [],
        crosshair: true
    }
    obj.series = [
        {
            name: 'Active',
            data: []
        },
        {
            name: 'Followup',
            data: []
        }
    ]

    //console.log(JSON.stringify(obj))
    //console.log("props.data: " + JSON.stringify(props.data))

    let active = 0
    let followup = 0
    props.data.forEach(item => {
        //console.log("item.trial_name: " + item.trial_name)
        //obj.xAxis.categories.push(item.trial_name)


        let a = 0
        let f = 0

        a = (item.crcType === 1 && item.active_patients ? item.active_patients : 0)


        f = (item.crcType === 1 && item.followup_patients ? item.followup_patients : 0)


        if (a > 0 || f > 0) {
            obj.xAxis.categories.push(item.trial_name)
            obj.series[0].data.push(a)
            active += a
            obj.series[1].data.push(f)
            followup += f
        }

    })

    obj.xAxis.categories.push('Total')
    obj.series[0].data.push(active)
    obj.series[1].data.push(followup)

    //console.log(JSON.stringify(obj))

    return obj;
})


</script>

<template>
    <Chart :options="graphData" v-if="data!=''"></Chart>
</template>