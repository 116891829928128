<script setup>
import ListPicker from './ListPicker.vue';
const props = defineProps({
    trial: Number,
    ctscores: Object,
    scoresheet_items: Array,
    is_editing: Boolean
})
console.log("props:" + JSON.stringify(props))

const grid_columns = ['attribute_name', 'score_description']
const emit = defineEmits(['itemUpdated'])

function getAttributeList(attribute_name) {
    return props.scoresheet_items.filter(a => a.attribute_name === attribute_name)
}


function addItemUpdate(updatedItem) {
    console.log("updatedItem:"+ JSON.stringify(updatedItem))
    // document.getElementById('score_value' + updatedItem.id).innerHTML = "<b>FOR UPDATE</b>"
    emit('itemUpdated',updatedItem)
}

</script>

<template>
    <table>
        <thead>
            <!-- <tr>
                <th colspan="3">
                    <span>{{ ctscores.protocol_name }} - {{ ctscores.status_name }}</span>
                </th>
            </tr> -->
            <tr>
                <th v-for="col in grid_columns" v-bind:key="col">{{ col }}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="score in ctscores.score_attribute_sets" v-bind:key="score.id">
                <td v-for="key in grid_columns" v-bind:key="key">
                    <div v-if="key != 'score_description'" :id="key + score.id">{{ score[key] }}</div>
                    <div v-else>
                        <ListPicker
                            v-if="is_editing"
                            :text="score[key]"
                            :list="getAttributeList(score['attribute_name'])"
                            :trial_id="trial"
                            :scoresheet_id="score.id"
                            :default="score.scoreSheetItem"
                            :attribute_id="score.scoreAttribute"
                            @item-update-event="addItemUpdate"
                        />
                        <div v-else>{{ score[key] }}</div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</template>