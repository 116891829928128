<script setup>

</script>

<template>
<user-widget />

  <router-view />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}

#nav {
  padding: 1em;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.logo-text{
  margin: 1em 2em -1em 2em;
  font-size: 18pt;
  font-weight: bold;
  color: #42b983;
}

.navbar{
  padding-bottom: 1em !important;
  margin-bottom: 1em;
  background-color: #e3f2fd;
}

h3{
    padding-top: 10px !important;
}

.w-3h{
    width: 300px;
}

.w-2h{
    width: 200px;
} 

th.sg-header{
  background-color: white !important;
  color:#337ec0 !important;
}

a {
  text-decoration: none !important;
}

a:hover {
  /* color: orange !important; */
  text-decoration: underline dotted !important;
} 

tr:hover{
  /* background-color: #FFEFD5; */
  /* font-weight: bold !important; */
  color:brown;
}

tr:nth-child(even) {
    background-color:#F9F9F7;
}
</style>
